import React from "react";
import PropTypes from "prop-types";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { SigninDialog } from "./Signin/SigninDialog";

const styles = createStyles({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

export interface Props extends WithStyles<typeof styles> {
  auth: boolean;
  firebaseAuth: firebase.auth.Auth;
}

export interface State {
  openDialog: boolean;
}

class MenuAppBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }
  onClose = () => {
    this.setState({
      openDialog: false
    });
  };
  render() {
    const { classes } = this.props;
    const { auth, firebaseAuth } = this.props;
    const renderLogin = (auth: any) => {
      if (auth) {
        return <a onClick={() => firebaseAuth.signOut()}>Sign-out</a>;
      } else {
        return (
          <a onClick={() => this.setState({ openDialog: true })}>Sign-In</a>
        );
      }
    };
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              ArkolahomaLBRA
            </Typography>
            {renderLogin(auth)}
          </Toolbar>
        </AppBar>
        <SigninDialog
          firebaseAuth={firebaseAuth}
          open={!this.props.auth && this.state.openDialog}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

(MenuAppBar as React.ComponentClass<Props>).propTypes = {
  classes: PropTypes.object.isRequired
} as any;

export default withStyles(styles)(MenuAppBar);
