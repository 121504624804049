import * as React from "react";
import { Dialog } from "@material-ui/core";
import { signInProviders } from "../../firebase/firebase";
import { StyledFirebaseAuth } from "react-firebaseui";
import firebaseui from "firebaseui";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  signInSuccessUrl: "/",
  signInOptions: signInProviders,
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};
interface Props {
  open: boolean;
  firebaseAuth: firebase.auth.Auth;
  onClose: () => void;
}
export const SigninDialog = (props: Props) => {
  return (
    <Dialog {...props}>
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={props.firebaseAuth}
      />
    </Dialog>
  );
};
