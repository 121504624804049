import React, { Component } from "react";
import "./App.css";
import MenuAppBar from "./components/MenuAppBar";
import { ResultsPage } from "./components/ResultsView/ResultsPage";
import { ResultsEntryPage } from "./components/ResultsEntry/ResultsEntryPage";
import { AddContantPage } from "./components/AddContestant/AddContestantPage";
import { GroupAndEvenSelector } from "./components/GroupAndEventSelector/GroupAndEventSelector";
import { auth } from "./firebase/firebase";
import { StandingsRepository } from "./firebase/standingsRepository";
import { CircularProgress } from "@material-ui/core";

const rodeos = {
  rodeo_1: "Rodeo 1",
  rodeo_2: "Rodeo 2",
  rodeo_3: "Rodeo 3",
  rodeo_4: "Rodeo 4",
  rodeo_7: "Rodeo 5",
  rodeo_8: "Rodeo 6",
  rodeo_9: "Rodeo 7",
  rodeo_10: "Rodeo 8",
  rodeo_11: "Rodeo 9",
  rodeo_12: "Rodeo 10",
  rodeo_13: "Rodeo 11",
  rodeo_14: "Rodeo 12",
  total: "Total"
};

export interface ContestantResults {
  firstName: string;
  lastName: string;
  contestantId: string;
  rodeo_1?: number;
  rodeo_2?: number;
  rodeo_3?: number;
  rodeo_4?: number;
  rodeo_5?: number;
  rodeo_6?: number;
  rodeo_7?: number;
  rodeo_8?: number;
  rodeo_9?: number;
  rodeo_10?: number;
  rodeo_11?: number;
  rodeo_12?: number;
  rodeo_13?: number;
  rodeo_14?: number;
  rodeo_15?: number;
  rodeo_16?: number;
  rodeo_17?: number;
  rodeo_18?: number;
  total?: number;
}

export interface Standings {
  [groupId: string]: {
    [eventId: string]: ContestantResults[];
  };
}
const littleWrangerEvents: Event[] = [
  { id: "littleWranglerBarrelRace", value: "Barrel Race" },
  { id: "littleWranglerFlagRace", value: "Flag Race" },
  { id: "littleWranglerPoleBending", value: "Pole Bending" },
  { id: "littleWranglerGoatUntying", value: "Goat Untying" },
  { id: "allAround", value: "All Around" }
];

const juniorGirlEvents: Event[] = [
  { id: "juniorGirlsTrailCource", value: "Trail Course" },
  { id: "juniorGirlsPoleBending", value: "Pole Bending" },
  { id: "juniorGirlsGoatTying", value: "Goat Tying" },
  { id: "juniorGirlsBreakAwayRoping", value: "Break Away" },
  { id: "juniorGirlsBarrelRace", value: "Barrel Race" },
  { id: "juniorGirlsTeamRoping", value: "Team Roping" },
  { id: "juniorGirlsRibbonRoping", value: "Ribbon Roping" },
  { id: "allAround", value: "All Around" }
];

const juniorBoyEvents: Event[] = [
  { id: "juniorBoysSaddleSteer", value: "Saddle Steer" },
  { id: "juniorBoysGoatTying", value: "Goat Tying" },
  { id: "juniorBoysFlagRace", value: "Flag Race" },
  { id: "juniorBoysBreakAwayRoping", value: "Break Away" },
  { id: "juniorBoysBareBack", value: "Bare Back" },
  { id: "juniorBoysTeamRoping", value: "Team Roping" },
  { id: "juniorBoysBullRiding", value: "Bull Riding" },
  { id: "juniorBoysRibbonRoping", value: "Ribbon Roping" },
  { id: "juniorBoysTieDownRoping", value: "Tie Down Roping" },
  { id: "allAround", value: "All Around" }
];
const seniorGirlEvents: Event[] = [
  { id: "seniorGirlsTrailRace", value: "Trail Course" },
  { id: "seniorGirlsPoleBending", value: "Pole Bending" },
  { id: "seniorGirlsGoatTying", value: "Goat Tying" },
  { id: "seniorGirlsBreakAwayRoping", value: "Break Away" },
  { id: "seniorGirlsBarrelRace", value: "Barrel Race" },
  { id: "seniorGirlsTeamRoping", value: "Team Roping" },
  { id: "seniorGirlsTrailCource", value: "Trail Course" },
  { id: "seniorGirlsRibbonRoping", value: "Ribbon Roping" },
  { id: "allAround", value: "All Around" }
];
const seniorBoyEvents: Event[] = [
  { id: "seniorBoysSaddleBronc", value: "Saddle Bronc" },
  { id: "seniorBoysTeamRoping", value: "Team Roping" },
  { id: "seniorBoysBullRiding", value: "Bull Riding" },
  { id: "seniorBoysRibbonRoping", value: "Ribbon Roping" },
  { id: "seniorBoysTieDownRoping", value: "Tie Down Roping" },
  { id: "allAround", value: "All Around" }
];

const groups: Group[] = [
  {
    id: "littleWrangler",
    value: "Little Wrangler",
    events: littleWrangerEvents
  },
  { id: "juniorGirls", value: "Junior Girls", events: juniorGirlEvents },
  { id: "juniorBoys", value: "Junior Boys", events: juniorBoyEvents },
  { id: "seniorGirls", value: "Senior Girls", events: seniorGirlEvents },
  { id: "seniorBoys", value: "Senior Boys", events: seniorBoyEvents }
];
export interface Event {
  id: string;
  value: string;
}
export interface Group {
  id: string;
  value: string;
  events: Event[];
}

export interface Contestant {
  firstName: string;
  lastName: string;
  contestantId: string;
}

export interface AllContestants {
  [groupId: string]: Contestant[];
}
class App extends Component<
  {},
  {
    rodeos: any;
    standings: Standings;
    contestants: AllContestants;
    auth: boolean;
    authUser?: firebase.User;
    groupId: string;
    eventId: string;
    loading: boolean;
  }
> {
  private repository: StandingsRepository;
  constructor(props: {}) {
    super(props);
    this.state = {
      rodeos: rodeos,
      contestants: {},
      auth: true,
      standings: {},
      loading: true,
      groupId: "littleWrangler",
      eventId: "littleWranglerBarrelRace"
    };
    this.repository = new StandingsRepository();

    auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ auth: true, authUser: authUser }))
        : this.setState(() => ({ auth: false, authUser: undefined }));
    });
  }

  async componentDidMount() {
    const loadedStandings = await this.repository.getAllData();
    const contestants = await this.repository.getAllContestants();
    this.setState({
      loading: false,
      standings: loadedStandings,
      contestants: contestants || {}
    });
  }
  getAllAround = (ageGroupResults: {
    [eventId: string]: ContestantResults[];
  }) => {
    const mergeResults = (
      first: ContestantResults,
      second: ContestantResults
    ): ContestantResults => {
      const { firstName, lastName, contestantId } = first;
      let mergedresult: ContestantResults = {
        firstName,
        lastName,
        contestantId
      };
      Object.keys(rodeos).forEach(key => {
        const firstResult = (first as any)[key];
        const secondResult = (second as any)[key];
        if (firstResult) {
          if (secondResult) {
            (mergedresult as any)[key] =
              Number.parseInt(firstResult) + Number.parseInt(secondResult);
          } else {
            (mergedresult as any)[key] = firstResult;
          }
        } else if (secondResult) {
          (mergedresult as any)[key] = secondResult;
        }
      });
      return mergedresult;
    };
    let allAroundResults: ContestantResults[] = [];
    Object.keys(ageGroupResults).map(eventId => {
      const eventResults = ageGroupResults[eventId];
      eventResults.map(row => {
        const foundContestant = allAroundResults.find(
          contestantResults =>
            contestantResults.contestantId === row.contestantId
        );
        if (!foundContestant) {
          allAroundResults.push(row);
        } else {
          const mergedResult = mergeResults(foundContestant, row);
          allAroundResults = allAroundResults.map(results => {
            if (results.contestantId === mergedResult.contestantId) {
              return mergedResult;
            } else {
              return results;
            }
          });
        }
      });
    });
    return allAroundResults;
  };

  handleAuthChange = (auth: boolean): void => {
    this.setState({ auth });
  };

  handleRowChange = async (row: ContestantResults) => {
    let found: boolean = false;
    const activeGroupId = this.state.groupId;
    const activeEventId = this.state.eventId;
    const existingStandings = this.state.standings;
    const existing = existingStandings[activeGroupId][activeEventId] || [];
    const contestants = existing.map(contestant => {
      if (contestant.contestantId === row.contestantId) {
        found = true;
        return row;
      }
      return contestant;
    });
    if (!found) {
      contestants.push(row);
    }
    const newStandings = {
      ...existingStandings,
      [activeGroupId]: {
        ...existingStandings[activeGroupId],
        [activeEventId]: contestants
      }
    };
    this.setState({ standings: newStandings }, () =>
      this.repository.saveGroup(activeGroupId, newStandings[activeGroupId])
    );
  };

  addNewContestant = (contestant: Contestant) => {
    let found = false;
    const existingContestants =
      this.state.contestants[this.state.groupId] || [];
    let newContestants = existingContestants.map(value => {
      if (value.contestantId === contestant.contestantId) {
        found = true;
        return contestant;
      } else {
        return value;
      }
    });
    if (!found) {
      newContestants.push(contestant);
    }

    this.setState(
      {
        contestants: {
          ...this.state.contestants,
          [this.state.groupId]: newContestants
        }
      },
      () => {
        this.repository.addContestant(this.state.groupId, newContestants);
      }
    );
  };

  contestantAdd = (contestant: Contestant) => {
    const initializeRow = (cont: Contestant): ContestantResults => {
      return {
        contestantId: cont.contestantId,
        firstName: cont.firstName,
        lastName: cont.lastName
      };
    };
    let found = false;
    const existingGroupStandings =
      this.state.standings[this.state.groupId] || {};
    const existingStandings = existingGroupStandings[this.state.eventId] || [];
    const newStandings = existingStandings.map(row => {
      if (row.contestantId === contestant.contestantId) {
        found = true;
        return {
          ...row,
          firstName: contestant.firstName,
          lastName: contestant.lastName
        };
      } else {
        return {
          ...row
        };
      }
    });
    if (!found) {
      newStandings.push(initializeRow(contestant));
    }
    this.setState({
      standings: {
        ...this.state.standings,
        [this.state.groupId]: {
          ...this.state.standings[this.state.groupId],
          [this.state.eventId]: newStandings
        }
      }
    });
  };

  setGroupAndEvent = (groupId: string, eventId: string) => {
    this.setState({ groupId, eventId });
  };
  render() {
    if (this.state.loading) {
      return <CircularProgress />;
    }
    let groupStandings: any = this.state.standings[this.state.groupId] || {};
    let standings = groupStandings[this.state.eventId] || [];
    if (this.state.eventId === "allAround") {
      standings = this.getAllAround(this.state.standings[this.state.groupId]);
    }
    const renderAdmin = (authUser?: firebase.User) => {
      if (
        authUser &&
        (authUser.uid === "PYAt2zyRKghRfByK7hDDFSUHVqw2" ||
          authUser.uid === "v6o6hOa3wTf3Pqc0HwQhVUNexZK2")
      ) {
        return (
          <div>
            <ResultsEntryPage
              onChange={this.handleRowChange}
              standings={standings}
              rodeos={this.state.rodeos}
            />
            <AddContantPage
              contestants={this.state.contestants[this.state.groupId]}
              onSubmit={this.addNewContestant}
              handleAddContestant={this.contestantAdd}
            />
          </div>
        );
      }
    };
    return (
      <div className="App">
        <MenuAppBar firebaseAuth={auth} auth={this.state.auth} />
        <GroupAndEvenSelector
          groups={groups}
          onEventChange={this.setGroupAndEvent}
          onGroupChange={this.setGroupAndEvent}
        />
        <ResultsPage standings={standings} rodeos={this.state.rodeos} />
        {renderAdmin(this.state.authUser)}
      </div>
    );
  }
}

export default App;
