import React, { useState } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { Contestant } from "../../App";

const styles = {
  marginLeft: "8px",
  marginRight: "8px",
  width: 200
};
interface AddContestantPageProps {
  contestants: Contestant[];
  onSubmit: (constestant: Contestant) => void;
  handleAddContestant: (contestant: Contestant) => void;
}
export const AddContantPage = (props: AddContestantPageProps) => {
  const [contestant, setContestant] = useState({
    firstName: "",
    lastName: "",
    contestantId: ""
  });
  const handleChange = (field: keyof Contestant) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContestant({ ...contestant, [field]: event.target.value } as any);
  };
  const handleSubmit = () => {
    props.onSubmit(contestant);
    setContestant({ firstName: "", lastName: "", contestantId: "" });
  };
  const addContestant = (contestant: Contestant) => {
    props.handleAddContestant(contestant);
  };
  const groupContestants = props.contestants || [];
  console.log(groupContestants);
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <List style={{ overflow: "auto", maxHeight: 300 }} dense={false}>
        {groupContestants.map(cont => (
          <ListItem
            button={true}
            key={cont.contestantId}
            onClick={() => addContestant(cont)}
          >
            <ListItemText
              primary={`${cont.contestantId} ${cont.firstName} ${
                cont.lastName
              }`}
            />
          </ListItem>
        ))}
      </List>
      <form noValidate autoComplete="off">
        <TextField
          id="first-name"
          label="First Name"
          style={styles}
          value={contestant.firstName}
          onChange={handleChange("firstName")}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="last-name"
          label="last Name"
          value={contestant.lastName}
          onChange={handleChange("lastName")}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="contestant-id"
          label="Contestant Id"
          value={contestant.contestantId}
          onChange={handleChange("contestantId")}
          margin="normal"
          variant="outlined"
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Primary
        </Button>
      </form>
    </div>
  );
};
