import { fireStore } from "./firebase";
import {
  ContestantResults,
  Standings,
  Contestant,
  AllContestants
} from "../App";
export class StandingsRepository {
  private standingsCollection: firebase.firestore.CollectionReference;
  private contestantsCollection: firebase.firestore.CollectionReference;
  constructor() {
    this.standingsCollection = fireStore.collection("standings");
    this.contestantsCollection = fireStore.collection("contestants");
  }
  public async saveGroup(
    groupId: string,
    scores: { [eventId: string]: ContestantResults[] }
  ): Promise<void> {
    return this.standingsCollection.doc(groupId).set(scores);
  }

  public async getAllData(): Promise<Standings> {
    const querySnapshot = await this.standingsCollection.get();
    let completeStandings = {};
    querySnapshot.forEach(result => {
      completeStandings = {
        ...completeStandings,
        [result.id]: result.data()
      };
    });
    return completeStandings;
  }
  public async addContestant(
    groupId: string,
    contestants: Contestant[]
  ): Promise<void> {
    return this.contestantsCollection.doc(groupId).set({ ...contestants });
  }

  public async getAllContestants(): Promise<AllContestants> {
    const querySnapshot = await this.contestantsCollection.limit(50).get();
    let allContestants = {};
    querySnapshot.forEach(result => {
      allContestants = {
        ...allContestants,
        [result.id]: Object.values(result.data())
      };
    });
    console.log({ allContestants });
    return allContestants;
  }
}
