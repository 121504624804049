import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { ContestantResults } from "../../App";

interface ResultsEntryPageProps {
  standings: ContestantResults[];
  rodeos: any;
  onChange: (standings: ContestantResults) => void;
}

export const ResultsEntryPage = (props: ResultsEntryPageProps) => {
  const standings = props.standings || [];
  const onChange = (row: ContestantResults) => {
    const total = calculateTotal(row);
    const calculatedRow = {
      ...row,
      total
    };
    props.onChange(calculatedRow);
  };

  const calculateTotal = (row: ContestantResults) => {
    let result: number = 0;
    Object.entries(row).forEach(entry => {
      if (entry[0].startsWith("rodeo_")) {
        result += Number.parseInt(entry[1]);
      }
    });
    return result;
  };
  const standingsWithTotal = standings.map(row => {
    const total = calculateTotal(row);
    return {
      ...row,
      total
    };
  });
  return (
    <div style={{ overflowX: "scroll" }}>
      <SimpleTable
        onChange={onChange}
        standings={standingsWithTotal.sort((a, b) => b.total - a.total)}
        rodeos={props.rodeos}
      />
    </div>
  );
};

interface SimpleTableProps {
  standings: ContestantResults[];
  rodeos: any;
  onChange: (standings: ContestantResults) => void;
}

const SimpleTable = (props: SimpleTableProps) => {
  const cellStyle: React.CSSProperties = { padding: 8, width: "2rem" };
  const handleChange = (row: ContestantResults) => (field: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (field !== "contestantId") {
      props.onChange({ ...row, [field]: event.target.value });
    } else {
      // check if in contestantsList
    }
  };
  return (
    <Paper>
      <Table padding={"dense"}>
        <TableHead>
          <TableRow>
            <TableCell>name</TableCell>
            <TableCell style={cellStyle}>Contestant ID</TableCell>
            {Object.entries(props.rodeos).map(entry => (
              <TableCell key={entry[0]} style={cellStyle}>
                {entry[1]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.standings.map(row => (
            <TableRow key={row.contestantId}>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField value={`${row.firstName} ${row.lastName}`} />
              </TableCell>
              <TableCell style={cellStyle}>
                <TextField
                  value={row.contestantId}
                  onChange={handleChange(row)("contestantId")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_1}
                  onChange={handleChange(row)("rodeo_1")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_2}
                  onChange={handleChange(row)("rodeo_2")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_3}
                  onChange={handleChange(row)("rodeo_3")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_4}
                  onChange={handleChange(row)("rodeo_4")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_5}
                  onChange={handleChange(row)("rodeo_5")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_6}
                  onChange={handleChange(row)("rodeo_6")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_7}
                  onChange={handleChange(row)("rodeo_7")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_8}
                  onChange={handleChange(row)("rodeo_8")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_9}
                  onChange={handleChange(row)("rodeo_9")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_10}
                  onChange={handleChange(row)("rodeo_10")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_11}
                  onChange={handleChange(row)("rodeo_11")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_12}
                  onChange={handleChange(row)("rodeo_12")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_13}
                  onChange={handleChange(row)("rodeo_13")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_14}
                  onChange={handleChange(row)("rodeo_14")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_15}
                  onChange={handleChange(row)("rodeo_15")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_16}
                  onChange={handleChange(row)("rodeo_16")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_17}
                  onChange={handleChange(row)("rodeo_17")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                <TextField
                  value={row.rodeo_18}
                  onChange={handleChange(row)("rodeo_18")}
                />
              </TableCell>
              <TableCell style={cellStyle} component="th" scope="row">
                {`${row.total}`}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};
