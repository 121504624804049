import * as React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Group } from "../../App";
export interface GroupAndEvenSelectProps {
  groups: Group[];
  onEventChange: (groupId: string, eventId: string) => void;
  onGroupChange: (groupId: string, eventId: string) => void;
}
export const GroupAndEvenSelector = (props: GroupAndEvenSelectProps) => {
  const initialActiveGroup = props.groups[0];
  const [activeGroup, setActiveGroup] = React.useState(initialActiveGroup);
  const [activeEvent, setActiveEvent] = React.useState(
    initialActiveGroup.events[0]
  );
  const handleGroupChange = (event: any) => {
    const group = props.groups.find(group => group.id === event.target.value);
    if (group) {
      setActiveGroup(group);
      setActiveEvent(group.events[0]);
      props.onGroupChange(group.id, group.events[0].id);
    }
  };
  const handleEventChange = (e: any) => {
    const foundEvent = activeGroup.events.find(
      event => event.id === e.target.value
    );
    if (foundEvent) {
      setActiveEvent(foundEvent);
      props.onEventChange(activeGroup.id, foundEvent.id);
    }
  };
  const renderAgeGroupSelector = (groups: any) => {
    return (
      <FormControl>
        <InputLabel htmlFor="age-simple">Group</InputLabel>
        <Select
          value={activeGroup.id}
          onChange={handleGroupChange}
          inputProps={{
            id: "group-simple"
          }}
        >
          {groups.map((group: any) => (
            <MenuItem key={group.id} value={group.id}>
              {group.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  const renderEventSelector = (events: any[]) => {
    return (
      <FormControl>
        <InputLabel htmlFor="age-simple">Event</InputLabel>
        <Select
          value={activeEvent.id}
          onChange={handleEventChange}
          inputProps={{
            id: "event-simple"
          }}
        >
          {events.map((event: any) => (
            <MenuItem key={event.id} value={event.id}>
              {event.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  return (
    <div>
      {renderAgeGroupSelector(props.groups)}
      {renderEventSelector(activeGroup.events)}
    </div>
  );
};
