import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyArsa_GYl2zzHUcOmrz-N8a4v35SXGz_BI",
  authDomain: "rodeo-app.firebaseapp.com",
  databaseURL: "https://rodeo-app.firebaseio.com",
  projectId: "rodeo-app",
  storageBucket: "rodeo-app.appspot.com",
  messagingSenderId: "954369146199"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const fireStore = firebase.firestore();

export const signInProviders = [firebase.auth.EmailAuthProvider.PROVIDER_ID];
