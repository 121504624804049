import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ContestantResults } from "../../App";

const styles = (theme: any) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

interface ResultsPageProps {
  standings: ContestantResults[];
  rodeos: any;
}
export class ResultsPage extends React.Component<ResultsPageProps, {}> {
  render() {
    const standings = this.props.standings || [];
    const sortedStandings = standings.sort((a, b) => {
      if (!b.total) {
        return -1;
      }
      if (!a.total) {
        return 1;
      }
      return b.total - a.total;
    });
    return (
      <div style={{ overflowX: "scroll" }}>
        <SimpleTable standings={sortedStandings} rodeos={this.props.rodeos} />
      </div>
    );
  }
}

interface SimpleTableProps {
  standings: ContestantResults[];
  rodeos: any;
}
function SimpleTable(props: SimpleTableProps) {
  return (
    <Paper>
      <Table padding={"dense"}>
        <TableHead>
          <TableRow>
            <TableCell>name</TableCell>
            {Object.entries(props.rodeos).map(entry => (
              <TableCell key={entry[0]}>{`${entry[1]}`}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.standings.map(row => (
            <TableRow key={row.contestantId}>
              <TableCell component="th" scope="row">
                {`${row.firstName} ${row.lastName}`}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_2}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_3}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_4}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_7}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_8}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_9}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_10}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_11}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.rodeo_12}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.total}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles<"root" | "table">(styles as any)(SimpleTable);
